.components-guideline {
    background-color: #eff3f6;
}

.components-guideline h3 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.components-guideline .g-row {
    /*text-align: left;*/
    margin-bottom: 15px;
}

.components-guideline .row .col:first-child,
.components-guideline .row .col-1:first-child,
.components-guideline .row .col-2:first-child,
.components-guideline .row .col-3:first-child,
.components-guideline .row .col-4:first-child{
    /*text-align: left;*/
    /*padding-left: 50px;*/
}
.components-guideline .row .col:last-child,
.components-guideline .row .col-1:last-child,
.components-guideline .row .col-2:last-child,
.components-guideline .row .col-3:last-child,
.components-guideline .row .col-4:last-child{
    /*padding-right: 50px;*/
}

.components-guideline .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
}