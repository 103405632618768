.rc-tooltip {
    z-index: 10000;
    opacity: 1;
}

.rc-tooltip-inner {
    padding: 6px 8px;
    font-size: 14px;
    color: #5e6c83;
    text-align: left;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #d8dde6;
    border-radius: 3px;
    box-shadow: 0 3px 5px 0 rgba(123, 139, 167, 0.25);
    min-height: 34px;
    max-width: 300px;
}
.rc-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: 4px;
    margin-left: -5px;
    border-width: 0;
    border-top-color: transparent;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
    left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    left: 4px;
    margin-top: -5px;
    border-width: 0;
    border-right-color: transparent;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
    top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    right: 4px;
    margin-top: -5px;
    border-width: 0;
    border-left-color: transparent;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
    top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: 4px;
    margin-left: -5px;
    border-width: 0;
    border-bottom-color: transparent;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: 15%;
}
