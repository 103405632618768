
.cookie-container {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    background: #1b75bcd1;
    color: #f5f6fa;
    padding: 30px 30px;
    box-shadow: 0 -2px 16px rgba(47, 54, 64, 39);
    transition: 400ms;
    display: block;
    margin: 0 auto;
    width: 100%;
}

.cookie-container.active {
    bottom: 0;
    z-index: 9999;
}

.cookies-text {
    font-size:16px;
    text-align:center;
}

.cookie-container a {
    color: #f5f6fa;
}
.cookies-check{
    font-size:14px;
    display: inline;
    padding-left:10px;
}
.cookie-btn {
    background: #45c28d;
    border: 0;
    color: #fff;
    padding: 12px 48px;
    font-size: 18px;
    margin-bottom: 16px;
    border-radius: 3px;
    font-family: 'Open Sans',sans-serif;
    cursor: pointer;
    margin-right: 10px;
}

.cookie-btn-rej{
    padding: 12px 48px;
    border: 0;
    border-radius: 3px;
    border: solid 1px #d8dde6;
    color:white;
    background-color: #ffffff2e;;
    margin-bottom: 16px;
    border-radius: 3px;
    font-family: 'Open Sans',sans-serif;
    cursor: pointer;
    margin-right: 10px;
}

.cookie-btn-privacy {
    background: #263857;
    border: 0;
    color: #fff;
    padding: 12px 48px;
    font-size: 18px;
    margin-bottom: 16px;
    border-radius: 3px;
    font-family: 'Open Sans',sans-serif;;
    cursor: pointer;

}
.cookie-btn,.cookie-btn-privacy{
    font-size: 16px;
}
@media only screen and (max-width: 768px) {

    .cookie-container {

        padding: 30px 30px;
        width: 100%;
    }
    .cookies-text{
        font-size:12px;
        text-align:left;
    }
    .cookie-btn,.cookie-btn-privacy,.cookie-btn-rej{
        font-size: 12px;
    }
    .cookie-btn,.cookie-btn-rej {
        margin-right: 0px;
    }

}
.components-guideline {
    background-color: #eff3f6;
}

.components-guideline h3 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.components-guideline .g-row {
    /*text-align: left;*/
    margin-bottom: 15px;
}

.components-guideline .row .col:first-child,
.components-guideline .row .col-1:first-child,
.components-guideline .row .col-2:first-child,
.components-guideline .row .col-3:first-child,
.components-guideline .row .col-4:first-child{
    /*text-align: left;*/
    /*padding-left: 50px;*/
}
.components-guideline .row .col:last-child,
.components-guideline .row .col-1:last-child,
.components-guideline .row .col-2:last-child,
.components-guideline .row .col-3:last-child,
.components-guideline .row .col-4:last-child{
    /*padding-right: 50px;*/
}

.components-guideline .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  background-color: #EFF3F6;
}


.Toastify__toast-container {
    z-index: 10000;
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: #fff; }
.Toastify__toast-container--top-left {
    top: 1em;
    left: 1em; }
.Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px; }
.Toastify__toast-container--top-right {
    top: 1em;
    right: 1em; }
.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em; }
.Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px; }
.Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em; }

@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        width: 100vw;
        padding: 0;
        left: 0;
        margin: 0; }
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
        top: 0; }
    .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
        bottom: 0; }
    .Toastify__toast-container--rtl {
        right: 0;
        left: auto;
        left: initial; } }

.Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    /*padding: 8px;*/
    /*border-radius: 1px;*/
    /*box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);*/
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr; }
.Toastify__toast--rtl {
    direction: rtl; }
.Toastify__toast--default {
    /*background: #fff;*/
    /*color: #aaa; */
}
.Toastify__toast--info {
    /*background: #3498db; */
}
.Toastify__toast--success {
    /*background: #07bc0c; */
}
.Toastify__toast--warning {
    /*background: #f1c40f; */
}
.Toastify__toast--error {
    /*background: #e74c3c; */
}
.Toastify__toast-body {
    margin: auto 0;
    flex: 1 1; }

@media only screen and (max-width: 480px) {
    .Toastify__toast {
        margin-bottom: 0; } }

.Toastify__close-button {
    display: none; /* no need we use custom buttons */
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    align-self: flex-start; }
.Toastify__close-button--default {
    color: #000;
    opacity: 0.3; }
.Toastify__close-button:hover, .Toastify__close-button:focus {
    opacity: 1; }

@-webkit-keyframes Toastify__trackProgress {
    0% {
        width: 100%; }
    100% {
        width: 0; } }

@keyframes Toastify__trackProgress {
    0% {
        width: 100%; }
    100% {
        width: 0; } }

.Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 5px;
    z-index: 9999;
    opacity: 0.7;
    -webkit-animation: Toastify__trackProgress linear 1;
            animation: Toastify__trackProgress linear 1;
    background-color: rgba(255, 255, 255, 0.7); }
.Toastify__progress-bar--rtl {
    right: 0;
    left: auto;
    left: initial; }
.Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@-webkit-keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0); }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0); }
    75% {
        transform: translate3d(10px, 0, 0); }
    90% {
        transform: translate3d(-5px, 0, 0); }
    to {
        transform: none; } }

@keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0); }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0); }
    75% {
        transform: translate3d(10px, 0, 0); }
    90% {
        transform: translate3d(-5px, 0, 0); }
    to {
        transform: none; } }

@-webkit-keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0); }
    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0); }
    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0); } }

@-webkit-keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0); }
    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0); }
    75% {
        transform: translate3d(-10px, 0, 0); }
    90% {
        transform: translate3d(5px, 0, 0); }
    to {
        transform: none; } }

@keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0); }
    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0); }
    75% {
        transform: translate3d(-10px, 0, 0); }
    90% {
        transform: translate3d(5px, 0, 0); }
    to {
        transform: none; } }

@-webkit-keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0); }
    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0); }
    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0); } }

@-webkit-keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0); }
    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0); }
    75% {
        transform: translate3d(0, 10px, 0); }
    90% {
        transform: translate3d(0, -5px, 0); }
    to {
        transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0); }
    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0); }
    75% {
        transform: translate3d(0, 10px, 0); }
    90% {
        transform: translate3d(0, -5px, 0); }
    to {
        transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0); }
    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0); }
    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0); }
    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0); }
    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0); } }

@-webkit-keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0); }
    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0); }
    75% {
        transform: translate3d(0, -10px, 0); }
    90% {
        transform: translate3d(0, 5px, 0); }
    to {
        transform: none; } }

@keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0); }
    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0); }
    75% {
        transform: translate3d(0, -10px, 0); }
    90% {
        transform: translate3d(0, 5px, 0); }
    to {
        transform: none; } }

@-webkit-keyframes Toastify__bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0); }
    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0); }
    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0); } }

@keyframes Toastify__bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0); }
    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0); }
    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
    -webkit-animation-name: Toastify__bounceInLeft;
            animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
    -webkit-animation-name: Toastify__bounceInRight;
            animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
    -webkit-animation-name: Toastify__bounceInDown;
            animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
    -webkit-animation-name: Toastify__bounceInUp;
            animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
    -webkit-animation-name: Toastify__bounceOutLeft;
            animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
    -webkit-animation-name: Toastify__bounceOutRight;
            animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
    -webkit-animation-name: Toastify__bounceOutUp;
            animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
    -webkit-animation-name: Toastify__bounceOutDown;
            animation-name: Toastify__bounceOutDown; }

@-webkit-keyframes Toastify__zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3); }
    50% {
        opacity: 1; } }

@keyframes Toastify__zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3); }
    50% {
        opacity: 1; } }

@-webkit-keyframes Toastify__zoomOut {
    from {
        opacity: 1; }
    50% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3); }
    to {
        opacity: 0; } }

@keyframes Toastify__zoomOut {
    from {
        opacity: 1; }
    50% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3); }
    to {
        opacity: 0; } }

.Toastify__zoom-enter {
    -webkit-animation-name: Toastify__zoomIn;
            animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
    -webkit-animation-name: Toastify__zoomOut;
            animation-name: Toastify__zoomOut; }

@-webkit-keyframes Toastify__flipIn {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        opacity: 0; }
    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in; }
    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1; }
    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
    to {
        transform: perspective(400px); } }

@keyframes Toastify__flipIn {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        opacity: 0; }
    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in; }
    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1; }
    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
    to {
        transform: perspective(400px); } }

@-webkit-keyframes Toastify__flipOut {
    from {
        transform: perspective(400px); }
    30% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1; }
    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0; } }

@keyframes Toastify__flipOut {
    from {
        transform: perspective(400px); }
    30% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1; }
    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0; } }

.Toastify__flip-enter {
    -webkit-animation-name: Toastify__flipIn;
            animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
    -webkit-animation-name: Toastify__flipOut;
            animation-name: Toastify__flipOut; }

@-webkit-keyframes Toastify__slideInRight {
    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInRight {
    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideInLeft {
    from {
        transform: translate3d(-110%, 0, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
    from {
        transform: translate3d(-110%, 0, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideInUp {
    from {
        transform: translate3d(0, 110%, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
    from {
        transform: translate3d(0, 110%, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideInDown {
    from {
        transform: translate3d(0, -110%, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
    from {
        transform: translate3d(0, -110%, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideOutRight {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutRight {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(110%, 0, 0); } }

@-webkit-keyframes Toastify__slideOutLeft {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(-110%, 0, 0); } }

@-webkit-keyframes Toastify__slideOutDown {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutDown {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(0, 500px, 0); } }

@-webkit-keyframes Toastify__slideOutUp {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(0, -500px, 0); } }

@keyframes Toastify__slideOutUp {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(0, -500px, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
    -webkit-animation-name: Toastify__slideInLeft;
            animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
    -webkit-animation-name: Toastify__slideInRight;
            animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
    -webkit-animation-name: Toastify__slideInDown;
            animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
    -webkit-animation-name: Toastify__slideInUp;
            animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
    -webkit-animation-name: Toastify__slideOutLeft;
            animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
    -webkit-animation-name: Toastify__slideOutRight;
            animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
    -webkit-animation-name: Toastify__slideOutUp;
            animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
    -webkit-animation-name: Toastify__slideOutDown;
            animation-name: Toastify__slideOutDown; }

/*# sourceMappingURL=ReactToastify.css.map */
.rc-tooltip {
    z-index: 10000;
    opacity: 1;
}

.rc-tooltip-inner {
    padding: 6px 8px;
    font-size: 14px;
    color: #5e6c83;
    text-align: left;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #d8dde6;
    border-radius: 3px;
    box-shadow: 0 3px 5px 0 rgba(123, 139, 167, 0.25);
    min-height: 34px;
    max-width: 300px;
}
.rc-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: 4px;
    margin-left: -5px;
    border-width: 0;
    border-top-color: transparent;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
    left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    left: 4px;
    margin-top: -5px;
    border-width: 0;
    border-right-color: transparent;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
    top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    right: 4px;
    margin-top: -5px;
    border-width: 0;
    border-left-color: transparent;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
    top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: 4px;
    margin-left: -5px;
    border-width: 0;
    border-bottom-color: transparent;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: 15%;
}

